import Carousel from 'react-bootstrap/Carousel';
import ProductCorosol1 from './components/product_corosol_1.jpg';
import ProductCorosol2 from './components/product_corosol_2.jpg';
import ProductCorosol3 from './components/product_corosol_3.jpg';
import ProductCorosol4 from './components/product_corosol_4.jpg';
import ProductCorosol5 from './components/product_corosol_5.jpg';
import ProductCorosol6 from './components/product_corosol_6.jpg';
// import './carousel.css'; // Ensure this path is correct for your CSS file
import React from 'react';


function IndividualIntervalsExample() {
  return (
    <Carousel>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol1}
          alt="First slide"
        />
        <Carousel.Caption className="d-none d-md-block">
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol4}
          alt="Fourth slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol5}
          alt="Fifth slide"
        />
        <Carousel.Caption className="d-none d-md-block">
          {/* <h3>Fifth slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2100}>
        <img
          className="d-block w-100"
          src={ProductCorosol6}
          alt="Sixth slide"
        />
        <Carousel.Caption className="d-none d-md-block">
          {/* <h3>Sixth slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default IndividualIntervalsExample;
