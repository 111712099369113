import React, { useState } from "react";
import "./QuoteRequestForm.css";

function QuoteRequestForm() {
  const [formData, setFormData] = useState({
    customerName: "",
    companyName: "",
    phoneNumber: "",
    deskNumber: "",
    extension: "",
    email: "",
    country: "",
    isReseller: "",
    additionalInfo: "",
    recordingLineType: "",
    lineCount: "",
    industryType: "",
    recordingReason: "",
    questions: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
    const payload = formData;
    console.log(payload,"payload");
  
    try {
      const response = await fetch('https://send-mail-sabari-communications.vercel.app/api/quote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        alert('Message sent successfully!');
        console.log('Response:', data);
        // You can reset the form or handle a success state here
      } else {
        const error = await response.text();
        console.log('Failed to send message: ' + error);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      console.log('An error occurred. Please try again.');
    }
  };

  return (
    <div>
       <div className="form-container">
          <h2>Request a Quote</h2>
          <form onSubmit={handleSubmit} className="quote-form">
            {[
              { label: "Customer Name", name: "customerName" },
              { label: "Company Name", name: "companyName" },
              { label: "Phone Number", name: "phoneNumber" },
              { label: "Desk Number", name: "deskNumber" },
              { label: "Ext.", name: "extension" },
              { label: "Email Address", name: "email" },
              { label: "Country Name", name: "country" },
              { label: "Type of Recordings Line", name: "recordingLineType" },
              { label: "Number of Line(s) to Record", name: "lineCount" },
              { label: "Type of Industry", name: "industryType" },
              { label: "Primary Reason for Recording", name: "recordingReason" },
            ].map((field, index) => (
              <div className="input-group" key={index}>
                <input
                  type={field.name === "lineCount" ? "number" : "text"}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.name !== "deskNumber" && field.name !== "extension"}
                />
                <label>{field.label}</label>
              </div>
            ))}

            <div className="input-group">
              <label>Are you a reseller?</label>
              <div className="radio-group">
                <label className={`radio-option ${formData.isReseller === "Yes" ? "active" : ""}`}>
                  <input
                    type="radio"
                    name="isReseller"
                    value="Yes"
                    checked={formData.isReseller === "Yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label className={`radio-option ${formData.isReseller === "No" ? "active" : ""}`}>
                  <input
                    type="radio"
                    name="isReseller"
                    value="No"
                    checked={formData.isReseller === "No"}
                    onChange={handleChange}
                  />
                  No
                </label>
              </div>
            </div>

            <div className="input-group">
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
              ></textarea>
              <label>Additional Information (Optional)</label>
            </div>

            <div className="input-group">
              <textarea
                name="questions"
                value={formData.questions}
                onChange={handleChange}
              ></textarea>
              <label>Any other questions or requests</label>
            </div>

            <button type="submit">Submit Request</button>
          </form>
        </div>
    </div>
   
  );
}

export default QuoteRequestForm;
