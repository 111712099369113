import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const MyCarousel = () => {
  return (
    <Carousel showThumbs={false} autoPlay infiniteLoop>
        <div className="backroun-img">
          <div className="background-layer">
            <div className="content">
              <h3 className="company-name-content animate-up">Sabari Communications</h3>
              <p className="company-discripition animate-up">Your Partner in Advanced Voice, Security, and Connectivity Solutions.</p>
            </div>
          </div>
        </div>

        <div className="backroun-img corocel-home-two">
            <div className="content carocel-content">
                <h3 className="carocel-content-header animate-up">Sabari Communications Call Recording Software</h3>
                <p className="carocel-content-discripition animate-up">Reliable Robust Market Proven… <br/>   Record Analog Lines,PBX Extns,E1T1 ISDNPRI,VOIP,GSM,Radio-Analog
                </p>
            </div>
        </div>
    
        <div className="backroun-img carocel-home-three">
            <div className="content carocel-content">
                <h3 className="carocel-content-header animate-up">GSM Range of Products</h3>
                <p className="carocel-content-discripition animate-up">Connect your business with GSM World
                <br/>   Compact and Intelligent XN520 Series
                </p>
            </div>
        </div>

        <div className="backroun-img carocel-home-four">
            <div className="content carocel-content">
                <h3 className="carocel-content-header animate-up">IP-PBX VoIP Gateway</h3>
                <p className="carocel-content-discripition animate-up">Accelerate your voice communication Business.
                    <br/>  
                    It’s easy to use, reliable, flexible and at the right price
                </p>
            </div>
        </div>

        <div className="backroun-img carocel-home-five">
            <div className="content carocel-content">
                <h3 className="carocel-content-header animate-up"> Centralise Management software </h3>
                <p className="carocel-content-discripition animate-up hoem-4th-corocel">Aegis CMS dedicated to any company that need to manage all recording at centralize server.  

It’s easy to use, reliable, flexible and at the right price


                </p>
            </div>
        </div>
    </Carousel>
  );
};

export default MyCarousel;
