import annaiBuilders from './images/our_customers/annai_builders.png';
import hdfcBank from './images/our_customers/hdfc-bank-logo-vector-indian-260nw-2351748935.webp';
import irctc from './images/our_customers/IRCTC.svg';
import kaviya from './images/our_customers/kaviya.png';
import mothersHospital from './images/our_customers/mothers hospital.png';
import rane from './images/our_customers/rane.webp';
import sbiLogo from './images/our_customers/SBI-Logo.webp';
import rbiSeal from './images/our_customers/Seal_of_the_Reserve_Bank_of_India.svg';
import tataMotors from './images/our_customers/Tata-Motors.png';
import uber from './images/our_customers/uber.png';
import React from 'react';



const customerImages = [
    { src: hdfcBank, alt: 'HDFC Bank' },
    { src: sbiLogo, alt: 'SBI Logo' },
    { src: irctc, alt: 'IRCTC' },
    { src: rbiSeal, alt: 'Reserve Bank of India' },
    { src: tataMotors, alt: 'Tata Motors' },
  ];
  const customerImages1 = [
    { src: uber, alt: 'Uber' },
    { src: mothersHospital, alt: 'Mothers Hospital' },
    { src: rane, alt: 'Rane' },
    { src: annaiBuilders, alt: 'Annai Builders' },
    { src: kaviya, alt: 'Kaviya' },
  ]
  
  function OurCustomers() {
    const imageStyle = {
      width: '150px', 
       // height: '150px',
      objectFit: 'cover', 
      margin: '20px 40px', 
    };
  
    return (
      <div className='all-images'>
        <div className="our-customers">
            {customerImages.map((image, index) => (
            <img
                key={index}
                src={image.src}
                alt={image.alt}
                style={imageStyle} 
            />
            ))}
        </div>
        {/* <br/> */}
        <div className="our-customers">
            {customerImages1.map((image, index) => (
            <img
                key={index}
                src={image.src}
                alt={image.alt}
                style={imageStyle} 
            />
            ))}
        </div>
         
      </div>
    );
  }
  
  export default OurCustomers;