import React, { useState, useEffect } from 'react';
// import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

const reviews = [
  {
    name: "VIJAY B.",
    position: "Quality Specialist - NSG Manufacturing",
    review: "Customer-focused team with technical capabilities. Understands the requirements and provides low-code solutions.",
    rating: 5
  },
  {
    name: "Sanjay Banerjee",
    position: "Head of IT - NGI Insurance",
    review: "EZOFIS low-code workflow automation solution and their support team were very flexible to work with us in achieving what we wanted.",
    rating: 5
  },
  {
    name: "Alex Johnson",
    position: "Biometric Specialist",
    review: "The biometric devices we received were of top-notch quality and helped streamline our security protocols.",
    rating: 5
  },
  {
    name: "Emily Davis",
    position: "Security Manager",
    review: "The CCTV cameras provided were excellent in terms of both quality and ease of use. Highly recommended!",
    rating: 4
  },
  {
    name: "Paul Adams",
    position: "IT Manager",
    review: "The voice loggers helped us maintain our call records effectively. Great product and excellent customer support.",
    rating: 5
  },
  {
    name: "Laura White",
    position: "Operations Manager",
    review: "The EPABX system we installed works flawlessly. It has improved our communication efficiency.",
    rating: 4
  },
  // Add more reviews as needed
];

// // Group reviews in pairs
// const groupReviews = (reviews) => {
//   let groupedReviews = [];
//   for (let i = 0; i < reviews.length; i += 2) {// if mobile then i += 1
//     groupedReviews.push(reviews.slice(i, i + 2));// if mobile then i += 1
//   }
//   return groupedReviews;
// };
const groupReviews = (reviews, isMobile) => {
  const groupedReviews = [];
  for (let i = 0; i < reviews.length; i += isMobile ? 1 : 2) {
    groupedReviews.push(reviews.slice(i, i + (isMobile ? 1 : 2)));
  }
  return groupedReviews;
};

const ReviewCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Mobile threshold
  const groupedReviews = groupReviews(reviews, isMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Carousel
      interval={3000}
      indicators={false}
      nextIcon={<span className="carousel-control-next-icon" />}
      prevIcon={<span className="carousel-control-prev-icon" />}
      className="custom-carousel container"
    >
      {groupedReviews.map((group, index) => (
        <Carousel.Item key={index}>
          <div className="row justify-content-center ">
            {group.map((review, idx) => (
              <div className="col-md-6 col-sm-12 d-flex justify-content-center" key={idx}>
                <div className="review-card text-center">
                  <h5 className="font-weight-bold">{review.name}</h5>
                  <p className="position">{review.position}</p>
                  <p>{review.review}</p>
                  <div className="stars">
                    {'⭐'.repeat(review.rating)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ReviewCarousel;
