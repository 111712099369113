import React , { useState, useEffect }  from 'react';
import './ContactForm.css';
import phone from "../images/customer-service.gif";
import location from "../images/location.gif";
import email from "../images/dove.gif";



const ContactForm = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [formArray, updateFormArray] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: ''
  });

  // Function to handle form submission
  const sendDetailsToMail = async (e) => {
    e.preventDefault();
  
    // Define the payload from the form state
    const payload = {
      name: formArray.name,
      contactNumber: formArray.contactNumber,
      email: formArray.email,
      message: formArray.message,
    };
  
    try {
      const response = await fetch('https://send-mail-sabari-communications.vercel.app/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        alert('Message sent successfully!');
        console.log('Response:', data);
        // You can reset the form or handle a success state here
      } else {
        const error = await response.text();
        console.log('Failed to send message: ' + error);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      console.log('An error occurred. Please try again.');
    }
  };
  

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormArray((prevFormArray) => ({
      ...prevFormArray,
      [name]: value, // Dynamically update the relevant field
    }));
  };


  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="contact-section">
      <div className="contact-container">
        <div className="contact-form-container">
          <h4>Contact Us</h4>
          <form onSubmit={sendDetailsToMail}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      value={formArray.name}
                      onInput={handleInputChange}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={formArray.email}
                      onInput={handleInputChange}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="contactNumber"
                      value={formArray.contactNumber}
                      onInput={handleInputChange}
                      placeholder="contact Number"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      value={formArray.message}
                      onInput={handleInputChange}
                      placeholder="Message"
                      required
                    />
                  </div>
                  <button type="submit" className="btn-submit">Submit Now</button>
                </form>
        </div>

        <div className="map-container">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.5434459125425!2d80.1404791!3d13.1408475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5262e3a996fa9d%3A0x725728c3484b81b9!2sSabari%20Communications!5e0!3m2!1sen!2sin!4v1630589951340!5m2!1sen!2sin"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>

      {/* Address and Phone Number Section */}
      {!isMobile && <div className="contact-details">
        <div className='gif-container'>
          <img src={location} class="contactGifs" alt="locationGif"/>
          <div className="contact-info">
            {/* <h5>Address</h5> */}
            <p>Sabari Communications,</p>
            <p>8/13,Lokesh Nagar,thendral nagar east</p>
            <p>thirumullaivoyal, Chennai-600062.</p>
          </div>
        </div>
       

        <div >
          <div className='gif-container'>
            <img src={phone} class="contactGifs" alt="locationGif"/>
            <div className="contact-info">
              {/* <h5>Phone</h5> */}
              <p>+91-9841082532</p>
            </div>
          </div>
        </div>


        <div >
          <div className='gif-container'>
            <img src={email} class="contactGifs" alt="locationGif"/>
            <div className="contact-info">
              {/* <h5>mail</h5> */}
              <p>SabariCommunications@yahoo.com</p>
            </div>
          </div>
        </div>

      </div>}
    </div>
  );
};

export default ContactForm;
