import React from 'react';
import './ProductGrid.css';
import voice_logger_PC_Base_recording_solution_pic1 from './images/voice-loggers/voice logger PC Base recording solution-pic1.jpg';
import Standalone_Voice_Logger_Call_recording_solution_pic2 from './images/voice-loggers/Standalone Voice Logger Call recording solution-pic2.jpg';
import E1T1_ISDN_PRI_Single_Dual_ports_pic3 from './images/voice-loggers/E1T1 ISDN PRI Single & Dual ports-pic3.jpg';
import GSM_Voice_Terminal_XN520TURBO_pic4 from './images/voice-loggers/GSM Voice Terminal XN520TURBO-pic4.jpg'
import Fixed_Cellular_Terminal_XN520_GSM_FCT_pic5 from './images/voice-loggers/Fixed Cellular Terminal XN520 GSM FCT-pic5.jpg'
import Centralize_Management_software_with_customize_pop_up_facilityq_pic6 from './images/voice-loggers/Centralize Management software with customize pop up facilityq-pic6.jpg'

const products = [
  {
    id: 1,
    name: 'voice logger PC Base recording solution',
    imageUrl: `${voice_logger_PC_Base_recording_solution_pic1}`, 
  },
  {
    id: 2,
    name: 'Standalone Voice Logger Call recording solution',
    imageUrl: `${Standalone_Voice_Logger_Call_recording_solution_pic2}`,
  },
  {
    id: 3,
    name: 'E1T1 ISDN PRI Single/Dual ports',
    imageUrl: `${E1T1_ISDN_PRI_Single_Dual_ports_pic3}`,
  },
  {
    id: 4,
    name: 'GSM Voice Terminal XN520TURBO',
    imageUrl: `${GSM_Voice_Terminal_XN520TURBO_pic4}`,
  },
  {
    id: 5,
    name: 'Fixed Cellular Terminal XN520 GSM FCT',
    imageUrl: `${Fixed_Cellular_Terminal_XN520_GSM_FCT_pic5}`,
  },
  {
    id: 6,
    name: 'Centralize Management software with customize pop up facilityq',
    imageUrl: `${Centralize_Management_software_with_customize_pop_up_facilityq_pic6}`,
  },
];

const ProductGrid = () => {
  return (
    <div className="home-product-grid">
      {products.map((product) => (
        <div key={product.id} className="home-product-container">
          <img src={product.imageUrl} alt={product.name} className="home-product-image" />
          <div className="home-product-overlay">
            <div className="home-product-name">{product.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
