import React, { useState } from 'react';

import './home.css';
// import productImg from '../images/home-about.png'
// import productIMG from '../images/home-support.png'
import OurCustomers from './ourCustomersLogo.jsx'
import ReviewGrid from './reviews.jsx'
import MyCarousel from './MyCarousel'
import ProductGrid from './allProduct.jsx'
import gsmImage from './images/GSM_VOICE_TERMINAL/GSM.png';


function Home() {
  const [isMobile] = useState(window.innerWidth < 768); // Mobile threshold

  return (
    <div className="home">
      {isMobile ? (
  <div className="backroun-img">
    <div className="background-layer">
      <div className="content">
        <h3 className="company-name-content animate-up">
          Sabari Communications
        </h3>
        <p className="company-discripition animate-up">
          Your Partner in Advanced Voice, Security, and Connectivity
          Solutions.
        </p>
      </div>
    </div>
  </div>
) : (
  <MyCarousel />
)}

        

      <div>
        
        {/* home-about-us */}
      <div  className="container-fluid home-about-us">
        <div className="container p-5 about-us-contiainer">
          <div className="row mb-4">
          <div className="col-md-12 text-center">
  <div className="About-us-tittle" style={{ marginBottom: '20px' }}>
    <h3 className="mb-3 About-title" >
      About Sabari Communications
    </h3>
    <span ></span>
  </div>
  <p className="lead aboutus-content" >
    Founded in 2011, Sabari Communications is dedicated to providing cutting-edge security and communication solutions tailored to meet the diverse needs of our clients. With a decade of experience, we have built a reputation for delivering reliable products and services, from advanced surveillance systems to seamless communication networks. Our mission is to empower individuals and businesses through innovative technology that enhances safety and drives productivity. Committed to customer satisfaction, we strive to offer personalized solutions that align with the unique requirements of each client, ensuring quality and excellence in every aspect of our service.
  </p>
</div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card mb-4 product-support">
                <div className="card-body ">
                  <h4 className="card-title"> <span>Product</span></h4>
                  <div className="d-flex">
                    {/* <img src={productImg} alt="Product" className="img-fluid me-3" style={{ maxWidth: '150px' }} /> */}
                    <p className="card-text about-my-product" >
                      <strong className='about-my-products'>CCTV Camera</strong> <span className='about-product-discription'>Advanced surveillance solutions for enhanced security.</span><br/>
                      <strong className='about-my-products'>Call Billing Software</strong><span className='about-product-discription'> Efficient management of call records and billing.</span><br/>
                      {/* <strong className='about-my-products'>Home Security Products</strong><span className='about-product-discription'> Comprehensive systems to protect your home.</span><br/> */}
                      <strong className='about-my-products'>EPABX Telephones</strong> <span className='about-product-discription'>Reliable communication systems for businesses.</span><br/>
                      <strong className='about-my-products'>Voice Loggers</strong> <span className='about-product-discription'>Secure recording solutions for quality assurance.</span><br/>
                      <strong className='about-my-products'>Biometric Devices</strong> <span className='about-product-discription'>Cutting-edge technology for secure access control.</span><br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card mb-4 product-support">
                <div className="card-body">
                  <h4 className="card-title"> <span>Support</span></h4>
                  <div className="d-flex ">
                    {/* <img src={productIMG} alt="Support" className="img-fluid me-3" style={{ maxWidth: '150px' }} /> */}
                    <p className="card-text about-my-product">At Sabari Communications, we prioritize our customers by providing exceptional support and service.<br/>
                     Our knowledgeable team is available to assist you with any inquiries, technical issues, or product guidance.<br/>
                      We believe in building long-term relationships with our clients, ensuring they receive the help they need to maximize the benefits of our products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>


      {/* gsm content */}
      <div>
        <div className='About-us-tittle'>
            <h3 className='About-title gsm-voice-header'>GSM VOICE TERMINAL</h3>
            <span></span>
        </div>
        
        <div className="gsm-range">
          <div className='gsm-content'>
            {/* <h4 className='About-title'>Sabari Communications GSM Range of products</h4> */}
            
            <p className='aboutus-content'>
              Sabari Communications connects your business with the GSM world with its compact and intelligent GSM Voice Terminal XN520 series, which satisfies your cost cutting voice communication need and provide next generation features to basic level GSM Fixed Cellular Terminal. Sabari Communications GSM Voice Terminal, the advanced version of GSM FCT abridges the gap with crystal clear audio quality, offering plethora of Web based features. <br/><br/>

              Sabari Communications XN520 is a GSM Voice Terminal which have provision to connect GSM Sim with two parallel FXS Ports. FXS Port can be interface with PBX, telephone instrument, dial pad for making and receiving calls via GSM network during office hours from their desk<br/><br/>

              Sabari Communications XN520 series GSM Voice Terminals finds its applications in Call center, corporate offices, banks, share brokers Manufacturing units, hotels, residences and other such establishments.<br/>
            </p>
          </div>
          <div className='gsm-img'>
          <img className='gsm-img-element'  src={gsmImage} alt="gsmImage" />
          </div>
          

        </div>
      </div>
     
      {/* MORE PRODUCT */}

      <div className='more-product'>
        <div>
          <h4 className='more-product-title About-title'>TELEHONE CALL RECORDER</h4>
        </div>
        <div className='all-product-img-1 overall-product-container'>
          
          {/* <div className='all-product-content'>
            <img className='all-product-img ' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic1.jpg' alt='product img'/>
            <div className='all-prod-overlay'>
              <p className='product-name'>voice logger PC Base recording solution</p>
            </div>
          </div>

          <div className='all-product-content'>
            <img className='all-product-img' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic2.jpg' alt='product img'/>
            <div className='all-prod-overlay'>
              <p className='product-name'>Standalone Voice Logger Call recording solution</p>
            </div>
          </div> 

          <div className='all-product-content'>
            <img className='all-product-img' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic3.jpg' alt='product img'/>
            <div className='all-prod-overlay'>
            
            <p className='product-name'>E1T1 ISDN PRI Single/Dual ports</p>
            </div>
          </div> 
          
          <div className='all-product-content'>
            <img className='all-product-img' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic4.jpg' alt='product img'/>
            <div className='all-prod-overlay'>
              <p className='product-name'>GSM Voice Terminal XN520TURBO </p>
            </div>
          </div>
          
          <div className='all-product-content'>
            <img className='all-product-img' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic5.jpg' alt='product img'/>
            <div className='all-prod-overlay'>
              <p className='product-name'>Fixed Cellular Terminal XN520 GSM FCT </p>
            </div>
          </div>

          <div className='all-product-content'>
            <img className='all-product-img' src='https://www.aegisinformatics.com/images/gallery/portrait2/pic6.jpg' alt='product img'/>
            <div className='all-prod-overlay'>  
              <p className='product-name'>Centralize Management software with customize pop up facility</p>
            </div>
          </div> */}
          <ProductGrid/>
        </div>  

      </div>

      {/* customers */}
      <div className="our-customers-container">
          <div>
            <h2 className="our-customers-section-title">
              <span className="happycust">  Trusted by  worldwide organizations</span>
            </h2>
            <div className='our-customer-logo-container'>
              <OurCustomers/>
            </div>
          </div>
      </div>

      <ReviewGrid/>

      </div>
      
        
    </div>





  );
}

export default Home;
